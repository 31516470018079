import React from "react"
import { graphql } from "gatsby"
import { useTranslation } from "react-i18next"
import Layout from "../shared/components/layout"
import PageHeader from "../shared/components/page-header"
import AccordionSection from "../shared/components/accordion-section"

const FAQS = (props) => {
    const { pageContext } = props
    const { t } = useTranslation()

    const metas = {
        title: t("seo.faqs.title"),
        description: t("seo.faqs.description")
    }

    const sideAccordionItems = t("faqs.items", { returnObjects: true })


    return (
        <Layout
            metas={metas}
            pageContext={pageContext}
        >
            <PageHeader
                title={t("faqs.title")}
                description={t("faqs.description")}
                type="faqs"
            />

            <AccordionSection
                type="faqs"
                items={sideAccordionItems}
            />

        </Layout>
    )
}

export default FAQS

export const query = graphql`
query ($language: String!) {
  locales: allLocale(filter: { language: { eq: $language } }) {
    edges {
      node {
        ns
        data
        language
      }
    }
  }
}
`;